import React from 'react';
import {
  PageLayout,
  PageLayoutBody,
  PageLayoutHeader,
} from '../components/page-layout';
import SEO from '../components/seo';

const Index = () => (
  <PageLayout className="home-background">
    <SEO title="Home" />
    <PageLayoutHeader />
    <PageLayoutBody className="home-content text-center">
      Pianist, keyboardist, arranger and session player
    </PageLayoutBody>
  </PageLayout>
);

export default Index;
